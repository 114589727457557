if (document.getElementById('faq')) {

    document.getElementById("button_HowitWorks").onclick = function(event) {
        abrirExperiencias(event, "HowitWorks");
    }

    document.getElementById("button_HowtoBook").onclick = function(event) {
        abrirExperiencias(event, "HowtoBook");
    }

    document.getElementById("button_ArrivalsDepartures").onclick = function(event) {
        abrirExperiencias(event, "ArrivalsDepartures");
    }

    document.getElementById("button_GettingAround").onclick = function(event) {
        abrirExperiencias(event, "GettingAround");
    }

    document.getElementById("button_ThingstoDo").onclick = function(event) {
        abrirExperiencias(event, "ThingstoDo");
    }

    document.getElementById("button_FisttimesTips").onclick = function(event) {
        abrirExperiencias(event, "FisttimesTips");
    }


    function abrirExperiencias(e, a) {
        var t;
        var s = document.getElementsByClassName("tabcontent");
        var i = document.getElementsByClassName("tablinks");

        for (t = 0; t < s.length; t++) {
            s[t].style.display = "none";
        }

        for (t = 0; t < i.length; t++) {
            i[t].className = i[t].className.replace(" active", "");
        }

        document.getElementById(a).style.display = "block";
        e.currentTarget.className += " active";
    }


    $(document).ready(function() {

        if (window.location.href.indexOf("HowitWorks") > -1) {
            $(".faq .tab > .active").removeClass("active");
            $(".faq .tab > #button_HowitWorks").addClass("active");
            $(".faq #HowitWorks").css("display", "block");
        }

        if (window.location.href.indexOf("HowtoBook") > -1) {
            $(".faq .tab > .active").removeClass("active");
            $(".faq .tab > #button_HowtoBook").addClass("active");
            $(".faq #HowtoBook").css("display", "block");
        }

        if (window.location.href.indexOf("ArrivalsDepartures") > -1) {
            $(".faq .tab > .active").removeClass("active"),
                $(".faq .tab > #button_ArrivalsDepartures").addClass("active"),
                $(".faq #ArrivalsDepartures").css("display", "block");
        }

        if (window.location.href.indexOf("GettingAround") > -1) {
            $(".faq .tab > .active").removeClass("active");
            $(".faq .tab > #button_GettingAround").addClass("active");
            $(".faq #GettingAround").css("display", "block");
        }

        if (window.location.href.indexOf("ThingstoDo") > -1) {
            $(".faq .tab > .active").removeClass("active");
            $(".faq .tab > #button_ThingstoDo").addClass("active");
            $(".faq #ThingstoDo").css("display", "block");
        }

        if (window.location.href.indexOf("FisttimesTips") > -1) {
            $(".faq .tab > .active").removeClass("active");
            $(".faq .tab > #button_FisttimesTips").addClass("active");
            $(".faq #FisttimesTips").css("display", "block");
        }
    });
}